import { useState, useEffect, useRef }  from 'react';
import videoWatchPartyLab from '../videos/watchPartyLab.mp4'
import videoMyGameName from '../videos/MyGameName.mp4'
import videoSpamcisum from '../videos/spamcisum.mp4'
import videoWatchPartyLabOgg from '../videos/watchPartyLab.mp4'
import videoMyGameNameOgg from '../videos/MyGameName.mp4'
import videoSpamcisumOgg from '../videos/spamcisum.mp4'

function Carousel() {

    useEffect(() => {
        document.title = 'TNEU - Completed';
      }, []);


    const svgWatch = '';

    const siteList = [
        {title: 'watchpartylab.com', logo : '../images/wpl.svg', description: 'Multi TWITCH web app built with Angular, PHP, and JSON.', link: 'https://watchpartylab.com', video: videoWatchPartyLab, videoOgg: videoWatchPartyLabOgg},
        {title: 'mygamename.com', logo : '../images/mgm.svg', description: 'Be creative by adding ϟ¥mßØLϟ and ª§¢Ìï characters to your game name.', link: 'https://mygamename.com', video: videoMyGameName, videoOgg: videoMyGameNameOgg},
        {title: 'spamcisum.com', logo : '../images/spamcisum.svg', description: 'An experiment in svg sprites and css animations.', link: 'https://spamcisum.com', video: videoSpamcisumOgg},
    ]

    function useInterval(callback, delay) {
        const savedCallback = useRef();
      
        // Remember the latest callback.
        useEffect(() => {
          savedCallback.current = callback;
        }, [callback]);
      
        // Set up the interval.
        useEffect(() => {
          let id = setInterval(() => {
            savedCallback.current();
          }, delay);
          return () => clearInterval(id);
        }, [delay]);
    }

    const [counter, setCounter] = useState(0);

      useInterval(() => {
        if(counter < siteList.length - 1){
            setCounter(counter + 1);
        }else{
            setCounter(0);
        }
        console.log(siteList[counter].video);
    }, 10000);  

    return (
        <div>
            <div className="sites">
                <a href={siteList[counter].link} target="_blank" rel="noopener noreferrer"><img className="sites-logo" src={siteList[counter].logo}></img></a>
                {/* <h1 className="cover-heading rainbow-text" data-text={siteList[counter].title}>{siteList[counter].title}</h1> */}
                <h3 className="description">{siteList[counter].description}</h3>
                {/* <p className="lead">
                    <a href={siteList[counter].link} target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-secondary">GO</a>
                </p> */}
                <p className="blur"></p>
            </div>
            <video id="videoBG" key={siteList[counter].video} poster={'../images/' + siteList[counter].title + '.png'} preload='metadata'  controls = '' autoPlay muted loop>
                <source src={siteList[counter].video + '#t=0.001'} type="video/mp4"/>
                <source src={siteList[counter].videoOgg + '#t=0.001'} type="video/ogg"></source>
            </video>
            <img className='videoImg' src={'../images/' + siteList[counter].title + '.png'}></img>
        </div>
    )
}
export default Carousel