import { useState, useEffect } from "react";

function Contact() {

    const [userMessage, setUserMessage] = useState("");
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [isValidMessage, setIsValidMessage] = useState(true);

    useEffect(() => {
        document.title = 'TNEU - Contact';
      }, []);

    const validateEmail = () => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
         {
           setMessage('');
           setIsValid(true);
           return (true)
         }else{
            setMessage('Please use name@example.com');
            setIsValid(false);
            return (false)
         }
    }

    const validateMessage = () => {
        if(userMessage.length === 0){
            setIsValidMessage(false);
            return (false)
        }
        setIsValidMessage(true);
        return (true)
    }

    const updateUserMessage = (inputMessage) => {
        setUserMessage(inputMessage);
    }

    const updateEmail = (mail) => {
        setEmail(mail);
        //validateEmail();
    }
    
    function handleSubmit(e) {
        e.preventDefault();
        validateEmail();
        if(!validateMessage()){
            return
        }
        console.log('You clicked submit.');
    }

    return (
        <div className="card p-3 mx-auto trans contact">
            <div className="card-header">
                Contact
            </div>
            <div className="card-body">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="FormControlInput">Email address</label>
                    <input type="email" className={`form-control ${isValid ? '' : 'error'}`} id="email" value={email} placeholder="name@example.com" onBlur={validateEmail}  onChange={e => updateEmail(e.target.value)}></input>
                    <p className="error-message" htmlFor="isValid">{message}</p>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">Message</label>
                    <textarea className={`form-control ${isValidMessage ? '' : 'error'}`} id="userMessage" value={userMessage} onChange={e => updateUserMessage(e.target.value)} rows="3"></textarea>
                </div>
                <div className="form-group text-right">
                <button type="submit" className="btn btn-primary">Submit</button>
                </div>
                
            </form>
            </div>
            <div className="contactBg"></div>
        </div>
    
    )
}
export default Contact