//import logo from './logo.svg';
import './App.css';
import Carousel from './components/carousel'
import Footer from './layouts/haf/footer';
import Header from './layouts/haf/header';
import { Redirect, Route, Switch } from 'react-router-dom';
import Contact from './layouts/contact';
import Lost from './layouts/lost';

function App() {
  return (
    <div className="w-100 h-100 p-3 d-flex justify-content-center flex-column">
      <Header/>
      <main role="main" className="inner cover">
        <div className="transBg"></div>
        <Switch>
        <Route path='/completed'>
          <Carousel/>
        </Route>
        <Route path='/contact'>
          <Contact/>
        </Route>
        <Route path='/'>
          <Redirect to='/completed'></Redirect>
        </Route>
        <Route path="*">
          <Lost/>
        </Route>
        </Switch>
      </main>

      <Footer/>
    </div>
  );
}

export default App;
