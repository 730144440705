import { NavLink } from "react-router-dom"

function Header() {
    return (
        <header className="masthead mb-auto">
        <div className="inner">
          <h3 className="masthead-brand">TNEU</h3>
          <nav className="nav nav-masthead justify-content-center">
            <NavLink className="nav-link" to="/completed">Completed</NavLink>
            <NavLink className="nav-link" to="/contact">Contact</NavLink>
          </nav>
        </div>
      </header>
    )

}

export default Header