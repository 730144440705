function Footer() {
    return(
    <footer className="mastfoot mt-auto">
        <div className="inner">
          <p>Hello <a href="https://tomneumeyer.com/"> from </a><a href="/">TNEU</a>.</p>
        </div>
      </footer>
    )
}

export default Footer